<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-label="oc"
        role="img"
        viewBox="0 0 256 256"
        style="width: 24px; height: 24px"
    >
    <path d="M209.765 128.15c0 16.013-4.9 31.34-13.266 44.575l33.447 33.447a128.918 128.918 0 0 0 25.783-77.35c0-29.265-9.754-55.751-26.47-77.336l-32.776 32.729a79.442 79.442 0 0 1 13.267 43.888l.015.047z" fill="#B8D3F4"/><path d="M127.513 210.355c-45.367-.086-82.125-36.838-82.22-82.205c.069-45.386 36.835-82.166 82.22-82.252c16.732 0 32.058 4.901 44.607 13.267l32.744-32.776A128.965 128.965 0 0 0 127.514.636C57.123.636 0 57.76 0 128.15c0 70.359 57.124 127.513 127.513 127.513a125.126 125.126 0 0 0 77.351-26.501l-33.447-33.431c-11.861 9.754-27.157 14.624-43.904 14.624z" fill="#7FADF2"/>
    </svg>
</template>

<script>
export default {
    name: "ocIcon",
};
</script>
